import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components/macro';
import QRCode from 'qrcode';

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
  margin-bottom: 30px;
`;

const Form = styled.form`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #555;
  font-weight: bold;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
  box-sizing: border-box;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23555' d='M10.293 3.293L6 7.586 1.707 3.293A1 1 0 00.293 4.707l5 5a1 1 0 001.414 0l5-5a1 1 0 10-1.414-1.414z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  padding-right: 30px;
  box-sizing: border-box;
`;

const GeneratedLink = styled(TextArea)`
  background-color: #f0f0f0;
  color: #333;
  cursor: not-allowed;
  width: 100%;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
`;

const CopyButton = styled(Button)`
  background-color: ${props => props.success ? '#2ecc71' : '#3498db'};
  animation: ${props => props.success ? 'pulse 0.5s' : 'none'};

  &:hover {
    background-color: ${props => props.success ? '#27ae60' : '#2980b9'};
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const OpenLinkButton = styled(Button)`
  background-color: #e74c3c;

  &:hover {
    background-color: #c0392b;
  }
`;

const QRCodeImage = styled.img`
  display: block;
  margin: 20px auto 0;
  max-width: 200px;
`;

const domains = {
  h5: {
    test: 'https://edu.dev1.dachensky.com/financial/',
    staging: 'https://pre-financial.qianliao.net/financial/',
    production: 'https://m.qianliao.net/financial/'
  },
  backend: {
    test: 'https://edu-admin.dev1.dachensky.com/#/',
    staging: 'https://pre-edu-admin.qlchat.com/#/',
    production: 'https://edu-admin.qlchat.com/#/'
  }
};

const LinkInputForm = () => {
  const [link, setLink] = useState('https://edu.dev1.dachensky.com/financial/mine'); // 默认h5测试链接-个人主页
  const [environment, setEnvironment] = useState('test');
  const [project, setProject] = useState('h5');
  const [generatedLink, setGeneratedLink] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [qrCode, setQRCode] = useState('');

  const generateLink = useCallback(() => {
    if (link && environment && project) {
      const baseDomain = domains[project][environment];
      let pathAndQuery;
      if (project === 'backend') {
        // For backend, remove everything before '#/' and keep the rest
        const parts = link.split('#/');
        pathAndQuery = parts.length > 1 ? parts[1] : '';
      } else {
        // For h5, keep the behavior as before
        pathAndQuery = link.split('/').slice(3).join('/').replace(/^financial\//, '');
      }
      setGeneratedLink(`${baseDomain}${pathAndQuery}`);
    } else {
      setGeneratedLink('');
    }
  }, [link, environment, project]);

  const generateQRCode = useCallback(async () => {
    try {
      const qrCodeDataUrl = await QRCode.toDataURL(generatedLink);
      setQRCode(qrCodeDataUrl);
    } catch (error) {
      console.error('Error generating QR code:', error);
    }
  }, [generatedLink]);

  useEffect(() => {
    generateLink();
  }, [link, environment, project, generateLink]);

  useEffect(() => {
    if (generatedLink) {
      generateQRCode();
    }
  }, [generatedLink, generateQRCode]);

  const copyGeneratedLink = () => {
    navigator.clipboard.writeText(generatedLink).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  const openGeneratedLink = () => {
    if (generatedLink) {
      window.open(generatedLink, '_blank');
    }
  };

  return (
    <Container>
      <Title>链接转换工具</Title>
      <Form>
        <FormGroup>
          <Label htmlFor="link">链接：</Label>
          <TextArea

            id="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="请输入你想替换域名的链接（例如https://edu.dev1.dachensky.com/financial/mine）"
            required
            rows={3}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="environment">环境：</Label>
          <Select
            id="environment"
            value={environment}
            onChange={(e) => setEnvironment(e.target.value)}
            required
          >
            <option value="">请选择环境</option>
            <option value="test">测试</option>
            <option value="staging">灰度</option>
            <option value="production">正式</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="project">项目：</Label>
          <Select
            id="project"
            value={project}
            onChange={(e) => setProject(e.target.value)}
            required
          >
            <option value="">请选择项目</option>
            <option value="backend">后台</option>
            <option value="h5">H5</option>
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="generatedLink">生成的链接：</Label>
          <GeneratedLink
            id="generatedLink"
            value={generatedLink}
            readOnly
            rows={3}
          />
          <CopyButton
            type="button"
            onClick={copyGeneratedLink}
            success={copySuccess}
          >
            {copySuccess ? '复制成功' : '复制链接'}
          </CopyButton>
          <OpenLinkButton
            type="button"
            onClick={openGeneratedLink}
            disabled={!generatedLink}
          >
            在新窗口中打开链接
          </OpenLinkButton>
        </FormGroup>
        {qrCode && (
          <QRCodeImage src={qrCode} alt="Generated QR Code" />
        )}
      </Form>
    </Container>
  );
};

export default LinkInputForm;

