import { HashRouter, Route, Routes } from "react-router-dom";
import List from "./pages/List";
import Home from "./pages/Home";
import QuickQrcode from "./pages/QuickQrcode";
import RecurseQuery from "./pages/RecurseQuery";
import Dm from "./pages/Dm"
import Ql from "./pages/Ql"
import "./App.css";

function App() {
  return (
    <HashRouter>
      <Routes>
	  <Route path="/" element={<List/>} exact />
	  <Route path="/home" element={<Home/>} />
	  <Route path="/quick-qrcode" element={<QuickQrcode/>}  />
	  <Route path="/recurse-query" element={<RecurseQuery/>}  />
	  <Route path="/dm" element={<Dm/>}  />
	  <Route path="/ql" element={<Ql/>}  />
      </Routes>
    </HashRouter>
  );
}

export default App;
