import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import dataList from './data'

const ListPage = styled.div`
  position: relative;
  margin: -96px auto -160px auto;
  max-width: 1392px;
  min-height: 100vh;
  padding: 220px 20px 40px;
  text-align: center;
  box-sizing: border-box;
  ::before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    pointer-events: none;
    background: no-repeat center / cover;
    background-image: url(https://gw.alipayobjects.com/zos/bmw-prod/a6c3488a-994c-4dd3-8e92-2324d9a1ca48/l9dmd9wl_w2858_h1864.png);
  }

  @media (max-width: 768px) {
    padding-top: 120px;
  }
`;

const Title = styled.h1`
  text-align: center;
  margin: 0 0 32px;
  display: inline-block;
  font-family: Alibaba-PuHuiTi, "Gill Sans", "Gill Sans MT", Calibri,
    "Trebuchet MS", sans-serif;
  color: #83cdf8;
  font-size: 80px;
  line-height: 1;
  > span {
    color: transparent;
    text-shadow: 0 10px 20px rgb(22 119 255 / 15%);
    background: linear-gradient(30deg, #90d5ff 30%, #65a5ff);
    -webkit-background-clip: text;
    background-clip: text;
  }

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const CardGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
`;

const Card = styled.div`
  height: 200px;
  width: 20%;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    width: 25%;
  }
  @media (max-width: 992px) {
    width: 33.3333%;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 480px) {
    width: 100%;
  }

  a {
    text-decoration: none;
    color: #1976d2;
  }
`;

const CardIn = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 100%;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */

  h3 {
    margin: 10px 0;
    color: #1976d2;
    font-size: 18px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  p {
    margin: 5px 0;
    color: #333;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 72%;
    width: 50%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.2;
    transition: all ease 1s;
  }

  &.react:before {
    background-image: url(${require("assets/react.svg").default});
  }
  &.vue:before {
    background-image: url(${require("assets/vue.svg").default});
  }

  &:hover:before {
    opacity: 0.8;
  }

  &:hover {
    transform: translateY(-5px);
  }

  > div {
    position: relative;
    z-index: 2;
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const List = (props) => {
  const [list, setList] = useState([]);

  const jumpLink = (link) => {
    window.location.href = link;
  }

  useEffect(() => {
    setList([...dataList]);
  }, []);

  return (
    <ListPage>
      <Title>
        <span>VOIDCUI开发常用小工具</span>
      </Title>
      <CardGroup>
        {list.map((item, index) => (
          <Card key={index}>
            {item.herf ? (
              <CardIn className={item.type} onClick={() => jumpLink(item.herf)}>
                <div>
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                  <p>作者：{item.author}</p>
                </div>
              </CardIn>
            ) : (
              <Link to={item.path}>
                <CardIn className={item.type}>
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.desc}</p>
                    <p>作者：{item.author}</p>
                  </div>
                </CardIn>
              </Link>
            )}
          </Card>
        ))}
      </CardGroup>
    </ListPage>
  );
};

export default List;

